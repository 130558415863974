













































































































































































.vueperslides__track-inner {
    transform: translate3d(calc(33% - (var(--offset) * 33%)), 0px, 0px) !important;
 }
